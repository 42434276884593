import React, { useState, useReducer, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Chip from '@material-ui/core/Chip';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FastForwardIcon from '@material-ui/icons/FastForward';
import IconButton from '@material-ui/core/IconButton';
import './style.css';
import SRPForm from './SRPForm';
import useCarrierApiError from './carrier-ui/hooks/useCarrierApiError';
import client from '../feathers';
import useIntl from '../hooks/useIntl';
import useAuth from '../hooks/useAuth';
import useWalletError from '../hooks/useWalletError';
import { useTerminals } from '../hooks/useTerminals';
import TMDataTable from '../lib/Table/TMDataTable';
import TMDialog from '../lib/Dialog/TMDialog';
import { getPincode, filterSelectedReleases, filterNonblockedReleases, filterPinAllowedLocations, filterAssignLocationsReleases } from '../util/pincode';
import { formatDate, formatIsoDate } from '../util/date';
import { validateRelease, createApiReleaseObjectFromObject, removeSpecialCharacters, getFormFields } from '../util/release';
import { isTokenValid } from '../util/token';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { findOrgName } from '../util/connections';
import GreenLightsPopover from './GreenLightsPopover';
import useSentry from '../hooks/useSentry';
import useTurnInHeaders from '../hooks/useTurnInHeaders';
import { checkAssignLocations } from '../services/releases';

const moment = require('moment');

const Containers = ({
  containers: releasesArray,
  refreshBills,
  onContainersSelect,
  selectedReleaseAddresses = [],
  showPincode = false,
  handleDialogClose,
  selectableRows = 'multiple',
  connections,
  connectedOrganizations,
  anonAddresses
}) => {

  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
  const [message, setMessage] = useState('Loading...');
  const [containers, setContainers] = useState([]);
  const [releaseAddressIndex, setReleaseAddressIndex] = useState(0);

  const [state, dispatch] = useReducer(reducer, initialState);

  const { translate } = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { user } = useAuth();
  const [terminals] = useTerminals();
  const onWalletError = useWalletError();
  const onCarrierApiError = useCarrierApiError();
  // determine the last column of the table by checking if we're in a dialog or not
  const showPincodeCol = handleDialogClose !== undefined;
  const isCarrierUser = user.organizationRole === 'carrier';
  const logSentry = useSentry();
  const { getTurnInLocationHeader, getTurnInReferenceHeader } = useTurnInHeaders();

  const openEditRelease = async (release) => {
    dispatch({
      payload: {
        ...release,
        validFrom: moment(release.validFrom).format('DD/MM/YYYY'),
        validUntil: moment(release.validUntil).format('DD/MM/YYYY'),
      },
    });
    setOpenEdit(true);
  };

  const openDeleteRelease = async (release) => {
    dispatch({
      payload: {
        ...release,
      },
    });
    setOpenDelete(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ payload: { [name]: value } });
  };

  const handleEditRelease = async () => {
    if (!isCarrierUser) {
      return;
    }

    showProgressDialog();

    try {
      const release = createApiReleaseObjectFromObject(state);
      validateRelease(release);
      await client.service('releases').patch(
        null, 
        release,
        {
          query: { address: { $in: [state.releaseAddress] }}
        }
      );

      // close the progress dialog
      hideProgressDialog();
      // close the dialog
      setOpenEdit(false);
      // reset the form
      dispatch({ type: 'RESET' });
      // refresh the list of bills and reload these containers
      await refreshBills();
    } catch (error) {
      // close the progress dialog
      hideProgressDialog();
      // handle the error
      logSentry(error);
      onCarrierApiError(error);
    }
  };

  const handleDeleteRelease = async () => {
    if (!isCarrierUser) {
      return;
    }

    try {
      showProgressDialog();
      const result = await client.service('releases').remove(state.releaseAddress);
      console.log("result", result)
      // close the progress dialog
      hideProgressDialog();
      // close the dialog
      setOpenDelete(false);
      // reset the form
      dispatch({ type: 'RESET' });
      // refresh the list of bills and reload these containers
      refreshBills();
      enqueueSnackbar(
        translate('carrierui.delete.complete'),
        { autoHideDuration: 3000 }
      );
    } catch (error) {
      // close the progress dialog
      hideProgressDialog();
      // handle the error
      logSentry(error);
      onCarrierApiError(error);
    }
  };

  const containersHeaders = () => [
    // 'containerNumber',
    { name: "containerNumber", // used for search
      label: translate('releaseOverview.containers.columns.containerNumber'),
      options: { display: false, viewColumns: false } 
    }, 
    { name: "containerNumber-filter",  // used for display
      label: translate('releaseOverview.containers.columns.containerNumber'),
      options: { searchable: false, filter: false } 
    }, 
    { name: "releaseStatus",
      label: translate('releaseOverview.containers.columns.releaseStatus'),
    }, 
    { name: 'releasedOn', 
      label: translate('releaseOverview.containers.columns.releasedOn'),
      options: { 
        sortCompare: (order) => {
          return (val1, val2) => {
            try {
              const date1 = formatIsoDate(val1.data);
              const date2 = formatIsoDate(val2.data);
              const direction = order === 'asc' ? 1 : -1;
              return date1 < date2 ? (-1 * direction) : date1 > date2 ? (1 * direction) : 0;
            } catch (err) {
              return 0;
            }
          };
        }
      }
    },
    // 'validFrom',
    { name: 'validFrom', 
      label: translate('releaseOverview.containers.columns.validFrom'),
      options: { 
        sortCompare: (order) => {
          return (val1, val2) => {
            try {
              const date1 = formatIsoDate(val1.data);
              const date2 = formatIsoDate(val2.data);
              const direction = order === 'asc' ? 1 : -1;
              return date1 < date2 ? (-1 * direction) : date1 > date2 ? (1 * direction) : 0;
            } catch (err) {
              return 0;
            }
          };
        }
      }
    },
    { name: 'validUntil', 
      label: translate('releaseOverview.containers.columns.validUntil'),
      options: { 
        sortCompare: (order) => {
          return (val1, val2) => {
            try {
              const date1 = formatIsoDate(val1.data);
              const date2 = formatIsoDate(val2.data);
              const direction = order === 'asc' ? 1 : -1;
              return date1 < date2 ? (-1 * direction) : date1 > date2 ? (1 * direction) : 0;
            } catch (err) {
              return 0;
            }
          };
        }
      }
    },
    'pickupPort',
    'pickupLocation',
    getTurnInLocationHeader('Truck'),
    getTurnInLocationHeader('Barge'),
    getTurnInLocationHeader('Rail'),
    getTurnInReferenceHeader('Truck'),
    getTurnInReferenceHeader('Barge'),
    getTurnInReferenceHeader('Rail'),
    {
      name: translate('releaseOverview.containers.columns.receivedFrom'),
      options: { 
        filter: user.features.canSeeTransferInfo && !isCarrierUser, 
        download: user.features.canSeeTransferInfo && !isCarrierUser, 
        display: !isCarrierUser, 
        viewColumns: !isCarrierUser 
      }
    },
    'transferStatus',
    { name: "Transferred to" },
    showPincodeCol ? 'pincode' : isCarrierUser ? 'action' : undefined,
  ]
  .filter((c) => c)
  .map((c) => typeof c === 'string' ? translate(`releaseOverview.containers.columns.${c}`) : c);

  useEffect(() => {

    const fetchContainers = async () => {

      let pincodeResults = [];
      let assignLocationsCheckResult=[];

      if (showPincode) {
        // We're going to use the wallet here to get the pincode, so check the token already here
        if (!isTokenValid()) {
          onWalletError(new Error(translate('session.expired')));
          return;
        }

        enqueueSnackbar(
          <div>
            {translate('pincode.retrieve.pins.progress')}
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </div>,
          { persist: true }
        );

        // check the assign locations for required_transfer_status
        assignLocationsCheckResult = await checkAssignLocations({ 
          releaseAddresses: selectedReleaseAddresses.length > 0 
            ? selectedReleaseAddresses 
            : releasesArray.map(r => r.releaseAddress), 
          anonAddresses 
        });

        const getPincodesForReleases = releasesArray
          .filter(r => filterSelectedReleases(selectedReleaseAddresses, r))
          .filter(r => filterNonblockedReleases(r))
          .filter(r => filterPinAllowedLocations(assignLocationsCheckResult, r))
          .filter(r => filterAssignLocationsReleases(assignLocationsCheckResult, r, user.features.canFetchPincode))
          .map(({address, version}) => ({address, version}));

        try {
          pincodeResults = await getPincode(getPincodesForReleases, user.organization);
          pincodeResults = pincodeResults
            .concat(
              // SCRDEV-1973: no pins for blocked containers - add replacement string for blocked containers
              releasesArray
                .filter(r => filterSelectedReleases(selectedReleaseAddresses, r))
                .filter(r => !filterNonblockedReleases(r))
                .map(r => ({ address: r.address, error: 'Not allowed to fetch pin (blocked)'}))
            )
            .concat(
              releasesArray
                .filter(r => filterNonblockedReleases(r))
                .filter(r => !filterPinAllowedLocations(assignLocationsCheckResult, r))
                .map(r => ({ address: r.address, error: 'Pins disabled'}))
            )
            .concat(
              // SCRDEV-2200: no pins for unassigned containers - add replacement string for these containers
              releasesArray
                .filter(r => filterSelectedReleases(selectedReleaseAddresses, r))
                .filter(r => filterNonblockedReleases(r))
                .filter(r => filterPinAllowedLocations(assignLocationsCheckResult, r))
                .filter(r => !filterAssignLocationsReleases(assignLocationsCheckResult, r, user.features.canFetchPincode))
                .map(r => ({ address: r.address, error: (r.isSecurePickup && !user.features.canFetchPincode) ? 'Not allowed to fetch pincode' : 'Must assign to fetch pin'}))
            )
        } catch (e) {
          showPincode && closeSnackbar();
          handleDialogClose && handleDialogClose();
          onWalletError(e);
        }
      }

      try {
        let _containers = await Promise.all(
          releasesArray?.map(async release => {
            let pincodeResult = pincodeResults.find(res => res.address === release.releaseAddress);
            if (pincodeResult?.pincode) {
              // SCRDEV-127: Set the right status after the pin is retrieved.
              // We could re-fetch the container here, but that's already done when closing the dialog.
              // So let's set it manually, purely for display purposes
              if (release.transferStatus === "owned") {
                release.transferStatus = "pinRetrieved";
              }
              pincodeResult = pincodeResult.pincode;
            } else {
              pincodeResult = pincodeResult ? pincodeResult.error : undefined;
            }
            
            let containerNumberWithLights = <span style={{paddingLeft: '26px'}}>{release.containerNumber}</span>;
            // SCRDEV-2429: Tussenoplossing voor MSC containers: geen GL op bepaalde terminals
            if (process.env.REACT_APP_MSC_ADDRESS=== release.creator && 
                process.env.REACT_APP_NO_GL_TERMINALS.split(',').includes(release.pickupLocation))
            {
              // Don't show GL, show shunting icon
              containerNumberWithLights = <Tooltip title={translate('extended.release.tooltip')}><span><FastForwardIcon/> {release.containerNumber}</span></Tooltip>;
            } else {
              // SCRDEV-2429: Otherwise, show GL if it matches the GL criteria
              if (process.env.REACT_APP_GREENLIGHTS_PORTS.split(',').includes(release.ediLocode) && (user.features.canGreenLights || release.isSecurePickup)) { 
                containerNumberWithLights = <GreenLightsPopover data={release.greenLights} text={release.containerNumber}/>
              }
            }

            // user.features.canSeeTransferInfo is also checked in the column array
            let receivedFrom = <Chip label="Go Premium" color="primary" size="small" clickable onClick={() => setOpenPremiumDialog(true)}/>
            if (user.features.canSeeTransferInfo) {
              if (release.prevParty) {
                if (release.prevParty.startsWith('address:')) {
                  receivedFrom = findOrgName(release.prevParty.replace('address:', ''), connections, connectedOrganizations)
                } else {
                  receivedFrom = release.prevParty;
                }
              } else {
                receivedFrom = translate('release.receivedFrom.unknown');
              }
            }
            
            let transferStatus = translate(
              release.transferStatus === 'assigned' && (release.assignedDriverName || release.assignedBargeName || release.assignedTrainName) ? `transferStatus.assigned.info` : `transferStatus.${release.transferStatus}`, 
              {
                // in case of 'transferStatus.assigned.info':
                type: release.assignedDriverName 
                  ? translate('driver.title') 
                  : (release.assignedBargeName 
                      ? translate('barge.title') 
                      : (release.assignedTrainName 
                          ? translate('train.title') 
                          : ''
                        )
                    ),
                name: release.assignedDriverName 
                  ? release.assignedDriverName 
                  : (release.assignedBargeName 
                      ? release.assignedBargeName 
                      : (release.assignedTrainName 
                          ? release.assignedTrainName 
                          : ''
                        )
                    ),
                visitNumber: release.assignedVisitNumber ? `(VN ${release.assignedVisitNumber})` : ''
              }
            );

            let nextParty = "";
            if (release.transferStatus === "transferred") {
              nextParty = <Chip label="Go Premium" color="primary" size="small" clickable onClick={() => setOpenPremiumDialog(true)}/>
            
              if (user.features.canSeeTransferInfo) { 
                if (release.nextParty) {
                  if (release.nextParty.startsWith('address:')) {
                    nextParty = findOrgName(release.nextParty.replace('address:', ''), connections, connectedOrganizations)
                  } else {
                    nextParty = release.nextParty;
                  }
                } else {
                  nextParty = translate('transferStatus.toNextParty');
                }
              }

              // check if nextParty is actually filled in or not
              if (!nextParty) {
                nextParty = translate('transferStatus.toNextParty');
              }
            }
            
            return [
              release.containerNumber,  /* used for search */
              containerNumberWithLights,
              translate(`releaseStatus.${release.releaseStatus}`),
              formatDate(release.createdAt),
              formatDate(release.validFrom),
              formatDate(release.validUntil),
              [undefined, null,''].includes(release.ediName) ? release.ediLocode : release.ediName,
              release.pickupLocation,
              release.turnInLocation,
              release.turnInLocationBarge,
              release.turnInLocationRail,
              release.turnInReference,
              release.turnInReferenceBarge,
              release.turnInReferenceRail,
              receivedFrom,
              transferStatus,
              nextParty,
              showPincodeCol ? (
                pincodeResult
              ) : isCarrierUser ? (
                  <React.Fragment>
                    <EditIcon style={{ color: '#666' }} onClick={() => openEditRelease(release)}/>
                    <DeleteIcon style={{ color: '#666' }} onClick={() => openDeleteRelease(release)}/>
                  </React.Fragment>
              ) : (
                <span />
              ),
              release.releaseAddress,
            ];
          })
        );
        
        setContainers(_containers);
        // used for 'onSelectionChange' in table
        setReleaseAddressIndex((_containers && _containers.length) ? _containers[0].length - 1 : 0);
        setMessage(translate('releaseOverview.containers.noContainersFound'));
        showPincode && closeSnackbar();
      } catch (e) {
        showPincode && closeSnackbar();
        handleDialogClose && handleDialogClose();
        onWalletError(e);
      }
    };

    releasesArray && fetchContainers();
  }, [releasesArray, connectedOrganizations]); // SCRDEV-1554: reload when connectedOrganizations becomes available, to correctly show the next party (instead of the 'next party' placeholder)

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="containers-table">
        <TMDataTable
          title={translate('releaseOverview.containers.title')}
          columns={containersHeaders()}
          data={containers}
          options={{
            customSearch: (searchQuery, currentRow, columns) => {
              let isFound = false;
              for(const val of currentRow) {
                if (typeof val === 'string' && removeSpecialCharacters(val.toLowerCase()).includes(removeSpecialCharacters(searchQuery.toLowerCase()))) {
                  isFound = true;
                  break;
                }
              }
              return isFound;
            },
            pagination: false,
            filter: true,
            print: false,
            fixedHeader: false,
            download: false,
            customToolbar: null,
            selectableRows,
            selectableRowsOnClick: selectableRows !== 'none',
            textLabels: {
              body: {
                noMatch: message,
              },
            },
            onSelectionChange: (selected) => {
              onContainersSelect &&
                onContainersSelect(
                  selected.map((release) => ({
                    releaseAddress: release[releaseAddressIndex],
                  }))
                );
            },
          }}
        />
      </div>

      <TMDialog
        key="edit-release"
        title={translate('carrierui.edit')}
        dialogOpen={openEdit}
        handleDialogClose={() => setOpenEdit(false)}
        maxWidth="md"
        showBottomClose={false}
      >
        <SRPForm
          object={state}
          needsConfirm={true}
          confirmMsg={translate(
            'carrierui.createRelease.section.bl.helperText'
          )}
          handleChange={handleChange}
          handleSubmit={handleEditRelease}
          submitLabel={translate('carrierui.edit')}
          handleCancel={() => setOpenEdit(false)}
          cancelLabel={translate("general.cancel")}
          fields={getFormFields(terminals, translate, 'edit')}
        />
      </TMDialog>

      <TMDialog
        key="delete-release"
        title={translate('carrierui.delete')}
        dialogOpen={openDelete}
        handleDialogClose={() => setOpenDelete(false)}
        handleSubmit={handleDeleteRelease}
        maxWidth="sm"
      >
        { translate("carrierui.delete.confirm", { containerNumber: state.containerNumber, blNumber: state.blNumber }) }
      </TMDialog>

      <TMDialog
        key="premium-transfer-info"
        title={translate('premium.transferinfo.title')}
        dialogOpen={openPremiumDialog}
        handleDialogClose={() => setOpenPremiumDialog(false)}
        maxWidth="md"
        showBottomClose={false}
      >
        <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '350px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-scr-received-from-and-transferred-to" />
      </TMDialog>
    </div>
  );
};

const initialState = {
  blNumber: '',
  portOfLoading: '',
  portOfDestination: '',
  vessel: '',
  agent: '',
  stayNumber: '',
  lloydsNumber: '',
  voyageNumber: '',
  containerNumber: '',
  isoTypeCode: '',
  pickupLocation: '',
  turnInLocation: '',
  validFrom: '',
  validUntil: '',
  turnInReference: '',
  releaseAddress: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...initialState };
    default:
      // = SET
      return { ...state, ...payload };
  }
};

export default Containers;
